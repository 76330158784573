import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./header-pro.scss"

const HeaderProducts = ({ className }) => {
  const { t } = useTranslation()
  return (
    <section className={(className ? className + " " : "") + "header-products"}>
      <Container fluid>
        <Row className="back-header-products">
          <Col
            className="back-header-text-products"
            xs={12}
            sm={12}
            md={7}
            lg={7}
          >
            <span className="our-offer">{t("Our Offer")}</span>
            <span className="products">{t("Products")}</span>
            <p>{t("If a stakeholder (employee, client, supplier, etc.)")}</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeaderProducts
